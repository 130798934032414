import logo from "../../../images/logo.svg";
import styled from "styled-components";
import { useContext } from "react";
import { MainContext } from "../../../ContextState";

export const HeaderStyled = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    flex-wrap: wrap;
    position: relative;

    .logo-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .logo {

    }
`;


function Header() {
    const { bookDataContext } = useContext(MainContext);
    const [bookData] = bookDataContext;

    let url = "https://mebook.ai/";
    if (bookData?.client?.websiteUrl) {
        url = bookData.client.websiteUrl;
    }

    return (
        <HeaderStyled>
            <div className="logo-container">
                <div className="logo">
                    <a href={ url } target="_blank" rel="noreferrer"><img src={ logo } alt="מיבוק לוגו"/></a>
                </div>
            </div>
        </HeaderStyled>
    );
}

export default Header;
