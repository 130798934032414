export const transformImage = (url, value) => {
    let result = url;
    if (url && value) {
        const wordAfter = "image/upload/";

        const parts = url.split(wordAfter);
        result = parts.join(wordAfter + value + "/");
    }
    return result;
};

export const getMobileQuery = () => {
    return window.innerWidth;
};

export const replaceUnsupportedImageExtension = (url) => {
    const supportedExtensions = [".jpg", ".jpeg", ".png", ".gif", ".webp"];
    const currentExtension = url.slice(url.lastIndexOf(".")).toLowerCase();
    if (!supportedExtensions.includes(currentExtension)) {
        // Replace the extension with '.jpg'
        const newUrl = url.slice(0, url.lastIndexOf(".")) + ".jpg";
        return newUrl;
    }
    return url;
};

// https://res.cloudinary.com/dqjknomcl/image/upload/l_text:Arial_24_bold:Colored%2520text,co_rgb:B90FAE/v1695824042/qdn2lre6glr8rlwjy2jo.png
export const addTextToCloudinaryImage = (url, coverDesign, templateTitle) => {
    let result = url;
    if (url && coverDesign && templateTitle) {
        let transedUrl = "";
        const splittedImage = url.split("/");

        const textSize = getFontSize(coverDesign.textSize);

        const color = coverDesign.textColor.replace("#", "");

        const value = `l_text:${ coverDesign.fontName }_${ textSize }_${ coverDesign.fontWeight }:${ templateTitle },co_rgb:${ color },o_100,g_south,y_150/`;

        const len = splittedImage.length - 1;
        splittedImage.forEach((part, idx) => {
            if (part.includes("c_crop")) {
                transedUrl += part + "/" + value;
            } else {
                transedUrl += part;
                if (len !== idx) {
                    transedUrl += "/";
                }
            }
        });

        return transedUrl;
    }
    return result;
};

export function makeImgAsPrint(image) {
    let result = image;
    if (image.includes("https://mebook.b-cdn.net/")) {
        result = image.replace("https://mebook.b-cdn.net/", "https://print.mebook.ai/");
    }

    if (image.includes("https://cdn.mebook.ai/")) {
        result = image.replace("https://cdn.mebook.ai/", "https://print.mebook.ai/");
    }

    return result;
}

export function getFontSize(sizeName, partOfBook) {
    let size = 15;
    switch (sizeName) {
        case "S":
            size = 25;
            break;
        case "M":
            size = 30;
            break;
        case "L":
            size = 40;
            break;
        case "XL":
            size = 50;
            break;
    }
    return size;
}

export function calculateFont(baseSize, width) {
    let fontSize = baseSize;
    const percent = width / 300 * 100; // cal
    const increaseFor = percent - 100;
    const plusNumber = fontSize / 100 * increaseFor;
    fontSize = fontSize + plusNumber;
    return Math.floor(fontSize);
}

export function calculateFontBaseSize(size, width) {
    let fontSize = size;
    const percent = 300 / width * 100; // cal
    const increaseFor = percent - 100;
    const plusNumber = fontSize / 100 * increaseFor;
    fontSize = fontSize + plusNumber;
    return fontSize;
}

export function getParams(url) {
    let params = {};
    let parser = new URL(url);
    for (let param of parser.searchParams.entries()) {
        params[param[0]] = param[1];
    }
    return { params, urlParser: parser };
}

export function createNewUrl(baseUrl, params) {
    let url = new URL(baseUrl);
    for (let key in params) {
        if (params.hasOwnProperty(key)) {
            url.searchParams.set(key, params[key]);
        }
    }
    return url.toString();
}

export function calculateFontSize(currentWidth, baseWidth = 814, baseFontSize = 24) {
    return (currentWidth / baseWidth) * baseFontSize;
}

export function removeDiacritics(text) {
    return text.normalize("NFD").replace(/[\u05B0-\u05BC\u05C1\u05C2\u05C4]/g, "");
}

export function fixSpacing(text) {
    if (!text) {
        return "";
    }
    return text.replace(/״/g, "\"");
}

export async function sleep(number) {
    return await new Promise(resolve => setTimeout(resolve, number));
}
