import axios from "axios";
import { API_URL } from "./consts";

export const getBook = async (id) => {
    const response = await axios.get(`${ API_URL }/editor/load?bookId=${ id }`, {});
    return response.data.data;
};

export const getBookJob = async (id) => {
    try {
        const response = await axios.get(`${ API_URL }/editor/book-jobs?bookId=${ id }`, {});
        return response.data.data;
    } catch (e) {

    }
};

export const verifyToken = async (token, bookId) => {
    const response = await axios.post(`${ API_URL }/editor/verify`, { bookId, token });
    return response.data;
};

export const finishBook = async (bookId, token, bookData) => {
    const response = await axios.post(`${ API_URL }/editor/approve-book`, { bookId, token, approvedData: bookData });
    return response.data.data;
};

export const saveBook = async (token, bookId, bookData, coverData) => {
    const response = await axios.post(`${ API_URL }/editor/save-book`, { bookId, token, approvedData: bookData, coverData });
    return response.data.data;
};

export const getConfig = async (lang, clientId) => {
    const response = await axios.get(`${ API_URL }/frame/config?lang=${ lang }&clientId=${ clientId }`);
    return response.data.data;
};

export const getBookPosition = async (bookId) => {
    const response = await axios.get(`${ API_URL }/editor/get-book-job-positions?bookId=${ bookId }`);
    return response.data.data;
};

export const setSelectCover = async (formData) => {
    const response = await axios.post(`${ API_URL }/frame/select-cover`, formData);
    return response.data.data;
};

export const bookStatus = async (bookId) => {
    const response = await axios.post(`${ API_URL }/frame/status`, {
        bookId: bookId
    });
    return response.data.data;
};

export const moreImages = async (bookId, pageIndex) => {
    const response = await axios.post(`${ API_URL }/editor/add-images`, {
        bookId: bookId,
        pageIndex
    });
    return response.data;
};

export const getJobStatusApi = async (jobId) => {
    const response = await axios.get(`${ API_URL }/editor/job-status`, {
        params: { jobId }
    });
    return response.data;
};

export const getPrebooks = async (phone) => {
    const response = await axios.post(`${ API_URL }/frame/recent-prebooks`, {
        phone
    });
    return response.data.data;
};

export const createCover = async (bookId, coverImageUrl, text, lang) => {
    const response = await axios.post(`${ API_URL }/editor/create-cover`, {
        bookId, coverImageUrl, text, lang
    });
    return response.data;
};

export const getBookJobPositions = async (bookId) => {
    const response = await axios.get(`${ API_URL }/editor/get-book-job-positions?bookId=${ bookId }`);
    return response.data.data;
};
