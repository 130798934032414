import React, {useContext, useEffect, useState} from 'react';
import {Outlet} from "react-router-dom";
import styled from "styled-components";
import {useLocation, useNavigate} from "react-router";
import {MainContext} from "../ContextState";
import {getBook} from "../ApiCalls";
import LogRocket from "logrocket";
import {APP_SETTINGS} from "../settings";
import Loader from "../common/Loader";
import Header from "./editor/components/Header";
import {setClientIdCookie} from "../common/cookie";
import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";
import { identifyUser } from "../common/eventManager";

const MainWrapperStyled = styled.div`
    @media (max-width: 1200px) {
        width: 100%;
    }
`;

const GTM_ID = "G-J2GWNKVXFR";

if (process.env.NODE_ENV === "production") {
    LogRocket.init('uamlxw/editor-prod-whbbz');

    Sentry.init({
        dsn: "https://ab0cfec146f51be5071ddd5f9ae7efd9@o4506189547044864.ingest.sentry.io/4506189555564544",
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: ["localhost", "mebook"],
            }),
            new Sentry.Replay(),
        ],
        tracesSampleRate: 1.0, // Capture 100% of the transactions
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

mixpanel.init("8b3a99edcc90e93fdf660d440d64eeaf", {
    debug: true,
    track_pageview: true,
    persistence: "localStorage",
});

const Wrapper = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(location.search);
    const lang = queryParameters.get("language") || 'he';
    const bookId = queryParameters.get("bookId");
    const clientId = queryParameters.get("clientId");

    const {bookDataContext} = useContext(MainContext);
    const [bookData, s_bookData] = bookDataContext;

    const [pageValid, s_pageValid] = useState(false);

    const goTo = (url) => {
        navigate(`${url}${location.search}`);
    };

    useEffect(() => {
        if (lang === 'he') {
            document.body.style.direction = 'rtl';
        } else {
            document.body.style.direction = 'ltr';
        }
    }, [lang]);

    useEffect(() => {
        if (clientId) {
            setClientIdCookie(clientId);
        }
    }, []);

    useEffect(() => {
        // Inject GTM script
        const gtmScript = document.createElement("script");
        gtmScript.async = true;
        gtmScript.src = `https://www.googletagmanager.com/gtag/js?id=${ GTM_ID }`;
        document.head.appendChild(gtmScript);

        // Initialize GTM gtag
        window.dataLayer = window.dataLayer || [];

        function gtag() {
            window.dataLayer.push(arguments);
        }

        gtag("js", new Date());
        gtag("config", GTM_ID);


        // israel pixel
        let FB_PIXEL_ID = "1097268434915469";
        if(clientId === '66bdbdba3e314dbad68940b8') {
            FB_PIXEL_ID = "563364439447860";
        }

        const fbScript = document.createElement("script");
        fbScript.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${ FB_PIXEL_ID }');
      fbq('track', 'PageView');
    `;
        document.head.appendChild(fbScript);

        return () => {
            // Cleanup scripts when component unmounts
            document.head.removeChild(gtmScript);
            document.head.removeChild(fbScript);
        };
    }, []);

    useEffect(() => {
        if (!bookData && bookId) {
            getBook(bookId).then(data => {
                s_bookData(data);

                LogRocket.identify(bookId, {
                    email: data.endUserEmail
                });
                identifyUser(bookData?.phone);

                switch (data.status) {
                    case APP_SETTINGS.bookStatus.inProcess:
                    case APP_SETTINGS.bookStatus.NOT_STARTED:
                        if (location.pathname !== APP_SETTINGS.pages.preview) {
                            goTo(APP_SETTINGS.pages.preview);
                        } else {
                            s_pageValid(true);
                        }
                        break;
                    case APP_SETTINGS.bookStatus.pendingPayment:
                        if (location.pathname !== APP_SETTINGS.pages.preview) {
                            goTo(APP_SETTINGS.pages.preview);
                        } else {
                            s_pageValid(true);
                        }
                        break;
                    case APP_SETTINGS.bookStatus.approved:
                    case APP_SETTINGS.bookStatus.GENERATING_PRINT_FILES:
                    case APP_SETTINGS.bookStatus.PRINT_FILES_GENERATED:
                    case APP_SETTINGS.bookStatus.GENERATING_PRINT_FILES_FAILED:
                    case APP_SETTINGS.bookStatus.CREATION_FAILED:
                    case APP_SETTINGS.bookStatus.COMPLETION_FAILED:
                        const url = `https://show.mebook.ai?bookId=${bookId}&language=${lang}`;
                        window.location.href = url;
                        // if (location.pathname !== APP_SETTINGS.pages.approved) {
                        //     goTo(APP_SETTINGS.pages.approved);
                        // } else {
                        //     s_pageValid(true);
                        // }
                        break;
                    case APP_SETTINGS.bookStatus.returned:
                        if (location.pathname !== APP_SETTINGS.pages.returned) {
                            goTo(APP_SETTINGS.pages.returned);
                        } else {
                            s_pageValid(true);
                        }
                        break;
                    case APP_SETTINGS.bookStatus.pendingUserApproval:
                        if (location.pathname !== APP_SETTINGS.pages.edit) {
                            goTo(APP_SETTINGS.pages.edit);
                        } else {
                            s_pageValid(true);
                        }
                        break;
                }
            }).catch(e => {
                console.log('errrrar', e);
            });
        } else {
            s_pageValid(true);
        }
    }, [location.pathname]);

    let classes = "";
    if (lang === 'he') {
        classes = 'lang-he';
    }

    let loading = (bookId && !bookData) || !pageValid;
    if (!bookId) {
        loading = true;
    }

    return (
        <MainWrapperStyled className={classes}>
            {loading && (
                <Loader size={200}/>
            )}
            {!loading && (
                <>
                    <Header/>
                    <Outlet/>
                </>
            )}
        </MainWrapperStyled>
    );
};

export default Wrapper;
