import React, { useContext, useEffect, useRef, useState } from "react";
import Joyride from "react-joyride";
import { useLocation } from "react-router";
import Loader from "../../common/Loader";
import PageItemEdit, { MoreMediaStyled } from "./components/PageItemEdit";
import coverWizard from "../../images/coverWizard.svg";
import Modal from "../../components/modal/Modal";
import Tutorial from "./components/Tutorial";
import PreviewBookAndFinish from "./components/book/PreviewBookAndFinish";
import Terms from "./components/Terms";
import BookCoverPrev from "./components/book/BookCoverPrev";
import { CoverPicsStyled, ReadyImagesCoverStyled } from "../preview/PreviewPage";
import { MainContext } from "../../ContextState";
import { finishBook, getBook, getConfig, getJobStatusApi, moreImages, saveBook, verifyToken } from "../../ApiCalls";
import {
    BackToWebsiteStyled,
    EditActionsStyled,
    EditorPageStyled,
    FinishedStyled, IsInvalidTokenStyled,
    NoBookIdStyled,
    PagesWrapperStyled
} from "../../styles/EditPreivewStyles";
import { getFontSize, sleep, transformImage } from "../../common/utils";
import { ButtonStyled, ButtonStyledV2 } from "../../styles/CommonStyled";
import styled from "styled-components";
import { chooseLang } from "../../common/chooseLang";
import EditCover from "../../components/EditCover";
import { EditSvg } from "../../svg";
import ConsentModal from "./components/anomalyModal/ConsentModal";

const GeneraeMoreCoversStyled = styled.div`
    margin-bottom: 40px;

    @media (max-width: 460px) {
        margin-bottom: 20px;
    }
`;

const ErrorsOfPages = styled.div`
    text-align: center;
    color: red;
    font-size: 18px;
`;

const GenerateMoreWarningStyled = styled.div`
    max-width: 460px;
    margin-top: 25px;
    font-size: 19px;

    @media (max-width: 767px) {
        width: 100%;
        min-width: 100%;
        min-height: 270px;
    }

    .more-accept-button {
        font-size: 22px;
        margin: 10px auto 0;
    }
`;


const EditorPage = () => {
    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);
    const lang = queryParameters.get("language") || "he";
    const bookId = queryParameters.get("bookId");
    const token = queryParameters.get("token");


    const isIframe = window.self !== window.top;
    const tutorialModal = localStorage.getItem("tutorialModeEditor");
    const gbCounterStorage = localStorage.getItem(`generate_book_${ bookId }`) || 0;

    const coversRef = useRef(null);
    const wrapperRef = useRef(null);

    const { bookDataContext, textAreaValidationContext, configContext } = useContext(MainContext);
    const [textAreaValidation] = textAreaValidationContext;
    const [bookData, s_bookData] = bookDataContext;
    const [config, s_config] = configContext;

    const [openTutorialModal, s_openTutorialModal] = useState(false);
    const [updatePage, s_updatePage] = useState(false);

    const [openTermModal, s_openTermModal] = useState(false);
    const [openEditCoverModal, s_openEditCoverModal] = useState(false);
    const [openEditCoverModalForce, s_openEditCoverModalForce] = useState(false);

    const [cancerModal, s_cancerModal] = useState(false);

    const [closeTutorialModalForce, s_closeTutorialModalForce] = useState(false);

    const [openPreviewBookModal, s_openPreviewBookModal] = useState(false);
    const [closePreviewBookModalForce, s_closePreviewBookModalForce] = useState(false);

    const [selectedBookCoverImage, s_selectedBookCoverImage] = useState("");
    const [textData, s_textData] = useState([]);
    const [imagesData, s_imagesData] = useState([]);
    const [sendingBookClose, s_sendingBookClose] = useState(false);
    const [savingBook, s_savingBook] = useState(false);
    const [bookDataSent, s_bookDataSent] = useState(false);
    const [isValid, s_isValid] = useState("pre");
    const [, s_errorCode] = useState(null);
    const [showReRenderWarning, s_showReRenderWarning] = useState(false);
    const [, s_showedReRenderWarning] = useState(false);
    const [rerenderImagesPageIdx, s_rerenderImagesPageIdx] = useState(null);
    const [closeReRenderWarningForce, s_closeReRenderWarningForce] = useState(false);

    const [moreImagesJobIds, s_moreImagesJobIds] = useState([]);

    const [height, s_height] = useState(0);

    const [, s_coverEdited] = useState(false);

    const { coverData } = bookData;

    const langData = chooseLang(lang);

    const gender = bookData?.inputData?.gender;

    const usesTimeLine = bookData?.client?.usesTimeline;

    useEffect(() => {
        document.documentElement.lang = lang;
        document.title = langData.main_title;
    }, [lang]);

    useEffect(() => {
        if (wrapperRef?.current) {
            s_height(wrapperRef.current.offsetWidth);
        }
    }, [wrapperRef?.current, bookData, updatePage]);

    useEffect(() => {
        if (bookId) {
            getConfig(lang, bookData.client?._id).then(data => {
                s_config(data);
            }).catch(e => {
                console.log("e", e);
            });
        }
    }, []);

    const openSelectionFunc = () => {
        s_closeTutorialModalForce(!closeTutorialModalForce);
        setTimeout(() => {
            s_openTutorialModal(!openTutorialModal);
        }, 200);
    };

    const openPreviewFunc = (e, isTerms, isClose) => {
        if (isClose) {
            s_closePreviewBookModalForce(true);
        } else {
            s_closePreviewBookModalForce(false);
        }

        setTimeout(() => {
            s_openPreviewBookModal(!openPreviewBookModal);
        }, 200);

        if (isTerms) {
            setTimeout(() => {
                openTermsFunc();
            }, 400);
        }
    };

    const openTermsFunc = () => {
        s_openTermModal(!openTermModal);
        setTimeout(() => {
            openPreviewFunc();
        }, 300);
    };

    const openCoverEditModal = (e, isClose) => {
        if (isClose) {
            s_openEditCoverModalForce(true);
        } else {
            s_openEditCoverModalForce(false);
        }
        setTimeout(() => {
            s_openEditCoverModal(!openEditCoverModal);
        }, 200);
    };

    const openCancerFunc = () => {
        s_cancerModal(!cancerModal);
    };

    const openReRenderFunc = (e, isClose) => {
        if (isClose) {
            s_closeReRenderWarningForce(true);
        } else {
            s_closeReRenderWarningForce(false);
        }
        setTimeout(() => {
            s_showReRenderWarning(!showReRenderWarning);
        }, 200);
    };

    const approveGenerateMoreImage = () => {
        generateMoreImagesApiCall(rerenderImagesPageIdx);
    };

    useEffect(() => {
        setTimeout(() => {
            s_updatePage(true);
        }, 1000);
    }, [bookData]);

    const finishBookHandle = async (isSave) => {
        if (savingBook) {
            return;
        }
        const dataToSend = {
            bookData: textData.map((text, idx) => {
                return {
                    pageIndex: idx,
                    selectedImageUrl: imagesData[idx],
                    text: text
                };
            })
        };

        dataToSend.bookData.unshift({
            pageIndex: -1,
            selectedImageUrl: selectedBookCoverImage,
            text: ""
        });

        if (isSave) {
            s_savingBook(true);
            saveBook(token, bookId, dataToSend.bookData, coverData).then(() => {
                s_savingBook(false);
            }).catch((e) => {
                console.log("e f bo", e);
            });
        } else {
            s_sendingBookClose(true);
            await saveBook(token, bookId, dataToSend.bookData, coverData);
            finishBook(bookId, token, dataToSend.bookData).then(() => {
                s_sendingBookClose(false);
                s_bookDataSent(true);
                s_openPreviewBookModal(false);
            }).catch((e) => {
                console.log("e f bo", e);
            });
        }
    };

    const resetText = (idx) => {
        const tempTextData = [...textData];
        tempTextData[idx] = bookData.bookPagesData[idx].text;
        s_textData(tempTextData);
    };

    const fetchAllJobStatuses = () => {
        moreImagesJobIds.forEach(job => getJobStatus(job));
    };

    const getJobStatus = async (job) => {
        if (job) {
            const jobStatus = await getJobStatusApi(job.jobId);
            if (jobStatus.status === "done") {
                await sleep(3000);
                getBook(bookId).then(data => {
                    s_bookData(data);
                });
                const moreImagesState = [...moreImagesJobIds];
                const moreImagesStateNew = moreImagesState.filter(pp => pp.jobId !== job.jobId);
                s_moreImagesJobIds(moreImagesStateNew);
            }
            if (jobStatus.status === "failed") {
                const moreImagesState = [...moreImagesJobIds];
                const moreImagesStateNew = moreImagesState.filter(pp => pp.jobId !== job.jobId);
                s_moreImagesJobIds(moreImagesStateNew);
            }
        }
    };

    useEffect(() => {
        if (bookData && bookId) {
            const data = bookData;
            const coverDesign = data.template.coverDesign;
            const textSizeD = coverDesign?.textSize;
            const fontSize = getFontSize(textSizeD);
            const templateTitle = gender === "female" ? bookData?.template?.titleFemale : bookData?.template?.titleMale;

            const tempTextData = [];
            const tempImagesData = [];
            const isSavedData = data?.approvedData?.length;


            if (isSavedData) {
                data?.approvedData?.forEach((item, idx) => {
                    if (item.pageIndex > -1) {
                        tempTextData.push(item.text);
                        tempImagesData.push(item.selectedImageUrl);
                    }
                });
            } else {
                data?.bookPagesData?.forEach((item, idx) => {
                    tempTextData[idx] = item.text;
                    if (item.us_u1) {
                        tempImagesData[idx] = item.us_u1;
                    } else {
                        tempImagesData[idx] = item.images?.[0];
                    }
                });
            }

            s_textData(tempTextData);
            s_imagesData(tempImagesData);
            if (isSavedData) {
                const cover = data.approvedData[0];
                s_selectedBookCoverImage(cover.selectedImageUrl);
            } else {
                s_selectedBookCoverImage(data.frontCoverData.selectedImageUrl);
            }

            if (!bookData?.coverData?.text) {
                const tempBookData = { ...bookData };
                tempBookData.coverData = {
                    text: templateTitle,
                    textColor: "#fff",
                    position: { x: 0, y: 10 },
                    textSize: fontSize
                };
                s_bookData(tempBookData);
            }
        }
    }, []);

    useEffect(() => {
        if (token && bookId) {
            verifyToken(token, bookId).then(data => {
                if (data.success) {
                    s_isValid("valid");
                } else {
                    s_isValid("invalid");
                    s_errorCode(data.errorCode);
                }
            }).catch(() => {
                s_isValid("invalid");
                s_errorCode(5001);
            });
        }
    }, []);

    useEffect(() => {
        if (moreImagesJobIds?.length) {
            const intervalId = setInterval(fetchAllJobStatuses, 3000); // Poll every 3 seconds

            // Clean up
            return () => clearInterval(intervalId);
        }
    }, [moreImagesJobIds]);

    const handleTextChange = (val, idx) => {
        const tempTextData = [...textData];
        tempTextData[idx] = val;
        s_textData(tempTextData);
    };

    const handleImageChange = (val, idx) => {
        const tempImageData = [...imagesData];
        tempImageData[idx] = val;
        s_imagesData(tempImageData);
    };

    const generateMoreImagesApiCall = async (idx) => {
        try {
            const data = await moreImages(bookId, idx);
            const moreImagesState = [...moreImagesJobIds];
            moreImagesState.push({
                jobId: data.jobId, idx: idx
            });
            s_moreImagesJobIds(moreImagesState);
            s_rerenderImagesPageIdx(null);
            getBook(bookId).then(data => {
                s_bookData(data);
            });
        } catch (e) {
            s_rerenderImagesPageIdx(null);
        }
    };

    const generateMoreImages = async idx => {
        s_showedReRenderWarning(true);
        s_showReRenderWarning(true);
        s_rerenderImagesPageIdx(idx);
    };

    let loading = bookId && !bookData;
    if (!loading && sendingBookClose) {
        loading = true;
    }
    if (!loading && isValid === "pre") {
        loading = true;
    }

    const isInvalid = isValid === "invalid";

    const noBookId = !bookId;

    const showData = !loading && !bookDataSent && !sendingBookClose && bookId && bookData && !isInvalid;
    let isAddress = false;
    let address = "";
    if (bookDataSent) {
        isAddress = true;
        if (bookData?.deliveryDetails?.city) {
            address += bookData?.deliveryDetails?.city;
        }
        if (bookData?.deliveryDetails?.street) {
            address += `, ${ bookData?.deliveryDetails?.street }`;
        }
        if (bookData?.deliveryDetails?.houseNumber) {
            address += `, ${ bookData?.deliveryDetails?.houseNumber }`;
        }
    }

    const isSelfDelivery = !bookData?.deliveryDetails;

    const templateTitle = coverData?.text;

    const coverDesign = bookData?.template?.coverDesign;

    const LOCALE = {
        skip: langData.tutorial_modal.skip,
        next: langData.tutorial_modal.next,
        back: langData.tutorial_modal.back,
        last: langData.tutorial_modal.last
    };
    const steps = [
        {
            target: ".coverPictures",
            content: <h4>{ langData.tutorial_modal.cov }</h4>,
            disableBeacon: true,
            locale: LOCALE
        },
        {
            target: ".item-xoip-0",
            content: <h4>{ langData.tutorial_modal.items }</h4>,
            disableBeacon: true,
            locale: LOCALE
        },
        {
            target: ".koidzd-doos-0",
            content: <h4>{ langData.tutorial_modal.texts }</h4>,
            disableBeacon: true,
            locale: LOCALE
        },
        {
            target: ".kjfchzx-pooidjx-0",
            content: <h4>{ langData.tutorial_modal.reset }</h4>,
            disableBeacon: true,
            locale: LOCALE
        },
        {
            target: ".save-button-sdz",
            content: <h4>{ langData.tutorial_modal.save }</h4>,
            disableBeacon: true,
            locale: LOCALE
        },
        {
            target: ".fire-button-sz",
            content: <h4>{ langData.tutorial_modal.finish }</h4>,
            disableBeacon: true,
            locale: LOCALE
        },
    ];

    const converImages = bookData?.frontCoverData?.images;

    const isInGenerating = moreImagesJobIds?.find(pp => pp.idx === -1);
    const showMoreImages = bookData?.regenerationsLeft > 0 && !isInGenerating;

    const errorPages = Object.keys(textAreaValidation);
    const isMulti = errorPages.length > 1;
    const fireButtonDisabled = errorPages.length > 0;

    const statusUrl = `https://statuses.mebook.ai/?orderId=${ bookData?.orderId }&lang=${ lang }`;

    return (
        <EditorPageStyled>
            { noBookId && (
                <NoBookIdStyled>
                    No book id
                </NoBookIdStyled>
            ) }
            { showData && (
                <PagesWrapperStyled
                    ref={ wrapperRef }
                >
                    <Joyride
                        callback={ (eee) => {
                            if (eee.status === "skipped" || eee.status === "finished" || eee.status === "close") {
                                localStorage.setItem("tutorialModeEditor", "1");
                            }
                        } }
                        run={ !tutorialModal && !isIframe }
                        steps={ steps }
                        continuous
                        scrollToFirstStep
                        showProgress
                        showSkipButton
                        styles={ {
                            options: {
                                primaryColor: "#FFA927"
                            }
                        } }
                    />
                    <h1>{ langData.main_title }</h1>
                    <CoverPicsStyled className="coverPictures" ref={ coversRef }>
                        { converImages.map((imageUrl, index) => {
                            const transformedImage = transformImage(imageUrl, "w_1500");
                            const isSelected = selectedBookCoverImage === imageUrl;

                            let classes = "coverImage bookIsReady";
                            if (isSelected) {
                                classes += " selected";
                            }

                            return (
                                <ReadyImagesCoverStyled
                                    tabIndex="0"
                                    role="button"
                                    aria-label={ `${ langData.arias.cover_selection } ${ index }` }
                                    key={ index }
                                    onClick={ (e) => {
                                        e.stopPropagation();
                                        s_selectedBookCoverImage(imageUrl);
                                        return false;
                                    } }
                                    className={ classes }>
                                    <BookCoverPrev
                                        wrapperWidth={ coversRef?.current?.offsetWidth || 0 }
                                        isSelected={ isSelected }
                                        index={ index }
                                        imageUrl={ transformedImage }
                                        lang={ lang }
                                        coverDesign={ coverDesign }
                                        coverData={ coverData }
                                        text={ templateTitle }
                                        contWidth={ height > 500 ? 250 : null }
                                    />
                                </ReadyImagesCoverStyled>
                            );
                        }) }
                    </CoverPicsStyled>
                    <span
                        onClick={ () => openCoverEditModal() }
                        className="edit-cover">
                        <EditSvg/>
                        { langData.title_editor_text?.edit_text }
                    </span>
                    <GeneraeMoreCoversStyled>
                        { showMoreImages && (
                            <MoreMediaStyled>
                                <p>
                                    { langData.more_images_editor }
                                    <span onClick={ () => {
                                        generateMoreImages(-1);
                                    } }>
                                    { langData.more_images_editor_2 }
                                </span>
                                </p>
                            </MoreMediaStyled>
                        ) }
                        { isInGenerating && (
                            <Loader size={ 30 }/>
                        ) }
                    </GeneraeMoreCoversStyled>
                    { bookData?.bookPagesData?.map((bookPage, idx) => {
                        return (
                            <PageItemEdit
                                langData={ langData }
                                key={ idx + bookPage._id }
                                item={ bookPage }
                                idxxx={ idx }
                                reset={ resetText }
                                textData={ textData[idx] }
                                imagesData={ imagesData[idx] }
                                handleTextChange={ val => handleTextChange(val, idx) }
                                handleImageChange={ val => handleImageChange(val, idx) }
                                generateMoreImages={ () => generateMoreImages(idx) }
                                moreImagesJobIds={ moreImagesJobIds }
                                gbCounterStorage={ Number(gbCounterStorage) }
                                regenerationsLeft={ bookData?.regenerationsLeft || 0 }
                            />
                        );
                    }) }
                    { fireButtonDisabled && (
                        <ErrorsOfPages>
                            { isMulti && (
                                <p>{ `${ langData.edit_text_validation?.errors_of_pages } ${ Object.keys(textAreaValidation).map(di => Number(di) + 1) }` }</p>
                            ) }
                            { !isMulti && (
                                <p>{ `${ langData.edit_text_validation?.errors_of_page } ${ Object.keys(textAreaValidation).map(di => Number(di) + 1) }` }</p>
                            ) }
                        </ErrorsOfPages>
                    ) }
                    <EditActionsStyled>
                        <ButtonStyledV2
                            disabled={ fireButtonDisabled }
                            className={ `fire-button-sz` }
                            aria-label={ langData.preview_and_make_book }
                            onClick={ () => openPreviewFunc() }
                            style={ { margin: "auto" } }>
                            { langData.preview_and_make_book }
                        </ButtonStyledV2>
                        <ButtonStyledV2
                            aria-busy={ savingBook }
                            aria-label={ langData.save }
                            className={ savingBook ? "isLoading save-button-sdz" : "save-button-sdz" }
                            onClick={ () => finishBookHandle(true) }
                            style={ { margin: "auto" } }>
                            <span>
                                { langData.save }
                            </span>
                        </ButtonStyledV2>
                    </EditActionsStyled>
                </PagesWrapperStyled>
            ) }
            { loading && (
                <Loader size={ 200 }/>
            ) }

            { bookDataSent && (
                <FinishedStyled
                    tabIndex="0"
                    aria-label={ langData.finished.book.one }
                >
                    { bookData?.printType === "book" && !isSelfDelivery && (
                        <>
                            <p>
                                { langData.finished.book.one }
                            </p>
                            { isAddress && (
                                <p>
                                    { langData.finished.book.second.replace("{{name}}", bookData.inputData.name) }
                                    { langData.finished.book.third.replace("{{days_num}}", `${ config?.deliveryDaysMin }-${ config?.deliveryDaysMax }`) }
                                </p>) }
                            { isAddress && (
                                <address>
                                    { address }
                                </address>
                            ) }
                            <img src={ coverWizard } alt="wizard"/>
                            <div className="finished-section">
                                <p>
                                    { langData.finished.mebook_info.one }
                                </p>
                                <p>
                                    <a href="mailto:info@MeBook.ai">info@MeBook.ai</a>
                                </p>
                            </div>
                            { usesTimeLine && (
                                <div className="finished-section">
                                    <p>
                                        <a href={ statusUrl } target="_blank" rel="noreferrer">{ langData.finished.to_watch_status }</a>
                                    </p>
                                </div>
                            ) }
                        </>
                    ) }
                    { bookData?.printType === "book" && isSelfDelivery && (
                        <>
                            <p>
                                { langData.finished.book_self_pick?.one }
                            </p>
                            <p>
                                { langData.finished.book_self_pick?.one_1 }
                            </p>
                            <p>
                                { langData.finished.book_self_pick?.second }
                            </p>
                            <p>
                                { langData.finished.book_self_pick?.second_1 }
                            </p>
                            <p>
                                { langData.finished.book_self_pick?.third?.replace("{{days_num}}", `${ config?.deliveryDaysMin }-${ config?.deliveryDaysMax }`) }
                            </p>
                            <p>
                                { langData.finished.book_self_pick?.third_1 }
                            </p>
                            <img src={ coverWizard } alt="wizard"/>
                            <div className="finished-section">
                                <p>
                                    { langData.finished.mebook_info.one }
                                </p>
                                <p>
                                    <a href="mailto:info@MeBook.ai">info@MeBook.ai</a>
                                </p>
                            </div>
                            { usesTimeLine && (
                                <div className="finished-section">
                                    <p>
                                        <a href={ statusUrl } target="_blank" rel="noreferrer">{ langData.finished.to_watch_status }</a>
                                    </p>
                                    <p>
                                        <a href={ statusUrl } target="_blank" rel="noreferrer">{ langData.finished.status_title }</a>
                                    </p>
                                </div>
                            ) }
                        </>
                    ) }
                    { bookData?.printType === "pdf" && (
                        <>
                            <p>
                                { langData.finished.pdf.one }
                            </p>
                            <p>
                                { langData.finished.pdf.second }
                            </p>
                            <address>
                                { bookData.endUserEmail }
                            </address>
                            <p>
                                { langData.finished.pdf.third }
                            </p>
                            <p>
                                { langData.finished.pdf.fourth }
                            </p>
                            <img src={ coverWizard } alt="wizard"/>
                            <p>
                                { langData.finished.mebook_info.one }
                            </p>
                            <p>
                                <a href="mailto:info@MeBook.ai">info@MeBook.ai</a>
                            </p>
                        </>
                    ) }
                    <BackToWebsiteStyled>
                        <a href={ bookData?.client?.websiteUrl }>{ langData.back_to_website }</a>
                    </BackToWebsiteStyled>
                </FinishedStyled>
            ) }

            { isInvalid && (
                <IsInvalidTokenStyled>
                    <div>
                        <p>{ langData.already_finished.first }</p>
                        <p>{ langData.already_finished.second }</p>
                        <p>
                            <a href="mailto:info@MeBook.ai">info@MeBook.ai</a>
                        </p>
                    </div>
                    <img src={ coverWizard } alt="wizard"/>
                </IsInvalidTokenStyled>
            ) }
            { showData && openTutorialModal && (
                <Modal
                    role="alertdialog"
                    ariaTitle={ langData?.tutorial_title }
                    removeClose={ true }
                    isOpen={ true } onClose={ openSelectionFunc } lang={ lang } closeModal={ closeTutorialModalForce }>
                    <Tutorial
                        onClose={ openSelectionFunc }
                        langData={ langData }
                    />
                </Modal>
            ) }
            { openPreviewBookModal && (
                <Modal
                    role="alertdialog"
                    ariaTitle={ "תצוגה מקדימה של הספר וסיום" }
                    isOpen={ true }
                    onClose={ e => openPreviewFunc(e, false, true) }
                    lang={ lang }
                    closeModal={ closePreviewBookModalForce }
                >
                    <PreviewBookAndFinish
                        selectedBookCoverImage={ selectedBookCoverImage }
                        bookData={ bookData }
                        lang={ lang }
                        langData={ langData }
                        textData={ textData }
                        imagesData={ imagesData }
                        onTermsClick={ e => {
                            openPreviewFunc(e, true);
                        } }
                        finishBookHandle={ (e) => {
                            openPreviewFunc(e, false, true);
                            setTimeout(() => {
                                // finishBookHandle();
                                openCancerFunc();
                            }, 500);
                        } }
                    />
                </Modal>
            ) }
            { openTermModal && (
                <Modal
                    ariaTitle={ langData.terms_part_2 }
                    isOpen={ true } onClose={ openTermsFunc } lang={ lang }>
                    <Terms
                        lang={ lang }
                        langData={ langData }
                    />
                </Modal>
            ) }

            { showReRenderWarning && (
                <Modal
                    ariaTitle={ langData.terms_part_2 }
                    isOpen={ true }
                    onClose={ openReRenderFunc }
                    lang={ lang }
                    closeModal={ closeReRenderWarningForce }
                >
                    <GenerateMoreWarningStyled>
                        { `${ langData.more_images_editor_warn } ${ langData.more_images_editor_warn_left } ${ bookData?.regenerationsLeft || 0 } ${ langData.more_images_editor_warn_left_1 }` }

                        <ButtonStyled
                            className="more-accept-button"
                            aria-label={ langData.preview_and_make_book }
                            onClick={ (e) => {
                                approveGenerateMoreImage();
                                openReRenderFunc(e, true);
                            } }
                        >
                            { langData.more_images_button_warn }
                        </ButtonStyled>
                    </GenerateMoreWarningStyled>
                </Modal>
            ) }

            { openEditCoverModal && (
                <Modal
                    isOpen={ true }
                    onClose={ openCoverEditModal }
                    closeModal={ openEditCoverModalForce }
                    lang={ lang }
                >
                    <EditCover
                        bookData={ bookData }
                        imageUrl={ selectedBookCoverImage }
                        lang={ lang }
                        coverDesign={ coverDesign }
                        coverData={ coverData }
                        onSave={ (data) => {
                            const tempBookData = { ...bookData };
                            tempBookData.coverData = data;
                            s_bookData(tempBookData);
                            openCoverEditModal(null, true);
                            s_coverEdited(true);
                        } }
                        templateTitle={ templateTitle }
                        contWidth={ height > 500 ? 250 : null }
                    />
                </Modal>
            ) }

            <ConsentModal
                lang={ lang }
                images={ imagesData }
                childName={ bookDataContext[0]?.inputData?.name }
                onConsent={ () => {
                    finishBookHandle();
                    s_cancerModal(false);
                } }
                isOpen={ cancerModal }
                onClose={ () => {
                    s_cancerModal(false);
                } }
            />

        </EditorPageStyled>
    );
};

export default EditorPage;
