import './styles/normalize.css';
import './styles/animation.css';
import './styles/fonts.css';
import 'react-tooltip/dist/react-tooltip.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {MainContextProvider} from "./ContextState";
import Wrapper from "./pages/Wrapper";
import {APP_SETTINGS} from "./settings";
import EditorPage from "./pages/editor/EditorPage";
import PreviewPage from "./pages/preview/PreviewPage";
import ProgressPage from "./pages/progress/ProgressPage";
import BooksIsApprovedPage from "./pages/approved/BooksIsApprovedPage";
import Resizer from "./common/Resizer";
import BooksIsReturnedPage from "./pages/returned/BooksIsReturnedPage";



function App() {
    return (
        <MainContextProvider>
            <div>
                <Resizer>
                    <Router>
                        <Routes>
                            <Route element={<Wrapper/>}>
                                <Route path='/' element={<div></div>}/>
                                <Route path={APP_SETTINGS.pages.edit} element={<EditorPage/>}/>
                                <Route path={APP_SETTINGS.pages.preview} element={<PreviewPage/>}/>
                                <Route path={APP_SETTINGS.pages.progress} element={<ProgressPage/>}/>
                                <Route path={APP_SETTINGS.pages.approved} element={<BooksIsApprovedPage/>}/>
                                <Route path={APP_SETTINGS.pages.returned} element={<BooksIsReturnedPage/>}/>
                            </Route>
                        </Routes>
                    </Router>
                </Resizer>
            </div>
        </MainContextProvider>
    );
}

export default App;
